// Breakpoints
$medium-screen: 600px;
$large-screen: 900px;



// Colors
$blue: #003151;
$blue-dark: #001929;
$red: #f25b56;
$accent: #40627C; // Accent Color
$offwhite: #f1f1f1;
$light-gray: #eee;
$medium-gray: #ccc;
$dark-gray: #777;
$black: #222;

$primary: $red;



// Border
$base-border: 1px solid $light-gray;



// Animations
$base-duration: 150ms;
$base-timing: ease;



@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}
