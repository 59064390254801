// Variables
@import "variables";



// Libraries
@import "libs/bootstrap/bootstrap";
@import "libs/font-awesome/font-awesome";



// Global Styles
body {
  background: #fff;
  color: $black;
  font-family: 'Source Sans Pro', serif;
  line-height: 1.5;
  font-weight: 400;
}

a {
  color: $accent;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($accent, 25%);
  }

  &.-nostyle {
    color: $black;
    transition: none;

    &:active,
    &:focus,
    &:hover {
      color: $black;
      text-decoration: none;
    }
  }
}

hr {
  display: block;
  border: none;
  border-bottom: 3px double $dark-gray;
  background: none;
  position: relative;
  width: 80%;
  margin: 2em auto;

  &::after {
    @extend .fa;
    background: $offwhite;
    content: $fa-var-calendar-o;
    position: absolute;
    top: -0.5em;
    left: 50%;
    margin-left: -0.5em;
  }
}



// Bootstrap Modifiers
strong,
th {
  font-weight: 600;
}
.btn {
  border-radius: 0;
  font-weight: 700;
  text-transform: uppercase;

  &-primary {
    color: #fff !important;
  }
}

legend {
  margin: 0;
  font-size: 1.3rem;
}
.form-control,
.form-select {
  border-radius: 0 !important;
}
.form-label {
  margin-bottom: 0.2rem;
}
.form-check-input[type=checkbox] {
  border-radius: 0;
}

.card {
  border: none;
  background: $offwhite;
  border-radius: 0 !important;
}
.card-header {
  background-color: $blue;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 1px 1px #000;
  font-weight: 100;
  border-radius: 0 !important;
}
.card-body {
  border-radius: 0 !important;
}
.card-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1;
}


// Helper Classes
.help-block {
  font-size: 11px;
}

.has-error {
  label:before {
    content: "* ";
    color: #a94442;
    text-transform: none;
  }

  input,
  select {
    border-color: #a94442;
  }
}



// Print
.print-view-only {
  display: none;
}

@media print {
  .not-printable {
    display: none !important;
  }

  .print-view-only {
    display: block !important;
  }
}
